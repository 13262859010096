// usePreventScrollInput.js
import { useEffect } from 'react';

const usePreventScrollInput = () => {
    useEffect(() => {
        const handleWheel = (event) => {
            if (event.target.tagName === 'INPUT' && event.target.type === 'number') {
                event.preventDefault();
            }
        };

        // Adding the event listener with the { passive: false } option
        window.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);
};

export default usePreventScrollInput;
